import axios from "axios";
import { MessageBox, Message } from "element-ui";
import { getToken1 } from "@/unit/auth";

const baseURL = window.PLATFROM_CONFIG.baseUrlsolar;
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: baseURL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers["Authorization"] = "Bearer " + getToken1();
    return config;
  },
  (error) => {
    // do something with request error
    console.log("error", error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      Message({
        message: res.message || "Error",
        type: "error",
        duration: 5 * 1000,
      });

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (
        res.code === 50008 ||
        res.code === 50012 ||
        res.code === 50014 ||
        res.code === 401 ||
        res.code === 408
      ) {
        // to re-login
        MessageBox.confirm(
          "You have been logged out, you can cancel to stay on this page, or log in again",
          "Confirm logout",
          {
            confirmButtonText: "Re-Login",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(() => {});
      }
      if (res.code === 201) {
        return Promise.reject(new Error(res.res.message || "Error"));
      }
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log(JSON.parse(JSON.stringify(error)));
    if (error.message === "timeout of 10000ms exceeded") {
      error.message = "请求超时";
    } else if (error.message === "Request failed with status code 401") {
      error.message = "已过期，请重新登录";
    } else {
      if (JSON.parse(JSON.stringify(error)).response.status === 404) {
        error.message = "账号密码错误";
      }
      if (JSON.parse(JSON.stringify(error)).response.status === 408) {
        error.message = "当前时间内无数据！";
      }
      if (JSON.parse(JSON.stringify(error)).response.status === 401) {
        error.message = "已过期，请重新登录";
      }
    }
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
