import request from "@/unit/request1";
export function getEnergyList(params) {
  return request({
    url: "api/v1/energyStation_list",
    method: "get",
    params,
  });
}
export function getEnergyListLat(params) {
  return request({
    url: "api/v1/energyStation_list_lat",
    method: "get",
    params,
  });
}
export function getGailan(params) {
  return request({
    url: "api/v1/gailan",
    method: "get",
    params,
  });
}
export function getReport(params) {
  return request({
    url: "api/v1/energyStation/report",
    method: "get",
    params,
  });
}
export function getDetail(params) {
  return request({
    url: "api/v1/energyStation/detail",
    method: "get",
    params,
  });
}
export function getBigDataMap(params) {
  return request({
    url: "api/v1/energyStation/getBigDataMap",
    method: "get",
    params,
  });
}
export function stationListTotal(params) {
  return request({
    url: "api/v1/energyStation/stationListTotal",
    method: "get",
    params,
  });
}
export function allstationReport(params) {
  return request({
    url: "api/v1/energyStation/allstationReport",
    method: "get",
    params,
  });
}
export function dengxiao(params) {
  return request({
    url: "api/v1/energyStation/dengxiao",
    method: "get",
    params,
  });
}

export function detail(params) {
  return request({
    url: "api/v1/energyStation/detail",
    method: "get",
    params,
  });
}
