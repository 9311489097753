export const guanfuOption = {
  backgroundColor: "transparent",
  title: {
    show: false,
    left: "2%",
    top: "4%",
    text: "",
    textStyle: {
      color: "#fff",
      fontSize: 18,
      fontWeight: 700,
    },
  },
  tooltip: {
    show: true,
    trigger: "axis",
    backgroundColor: "rgba(0,0,0,.75)",
    borderWidth: 0,
    textStyle: {
      color: "#fff",
      fontSize: getDpr(22),
    },
  },

  legend: {
    type: "scroll",
    top: 0,
    left: "center",
    itemWidth: 14,
    itemHeight: 5,
    itemGap: 13,
    data: ["功率"],
    right: "1%",
    pageIconColor: "#12F1FE",
    pageIconInactiveColor: "#fff",
    pageTextStyle: {
      color: "#fff",
    },
    textStyle: {
      fontSize: 14,
      color: "#fff",
    },
  },
  grid: [
    {
      top: "20%",
      left: "12%",
      bottom: "10%",
      right: "12%",
      containLabel: false,
    },
  ],
  xAxis: [
    {
      type: "time",
      boundaryGap: false,
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgba(0, 156, 249, 0.4)",
        },
      },
      axisLabel: {
        margin: 10,
        textStyle: {
          fontSize: getDpr(22),
          color: "#ffffff",
        },
      },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisLabel: {
        fontSize: getDpr(22),
        color: "rgba(0, 156, 249, 1)",
      },
      scale: true,
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: "#2cebf9",
        },
      },

      splitLine: {
        lineStyle: {
          color: "rgba(192, 198, 220,0.14)",
        },
      },
    },
  ],
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: "line",
      smooth: true,
    },
  ],
};
function getDpr(num) {
  let width = window.screen.width;
  if (width > 1920) {
    return num;
  } else {
    return num / 2;
  }
}
